import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ArcaInput = {
  amount: Scalars['Float'];
  orderNumber: Scalars['Float'];
  uid: Scalars['Float'];
};

export type ArcaStatusInput = {
  orderId: Scalars['String'];
  orderNumber?: InputMaybe<Scalars['Float']>;
  orderStatus?: InputMaybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type Banner = {
  __typename?: 'Banner';
  action: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  imageBR: Maybe<Scalars['String']>;
  imageTL: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title: Maybe<Scalars['String']>;
  translations: Maybe<Array<BannerTranslation>>;
  url: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type BannerCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  imageBR?: InputMaybe<Scalars['String']>;
  imageTL?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<BannerTranslationCreateInput>>;
  url?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type BannerTranslation = {
  __typename?: 'BannerTranslation';
  action: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  label: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  title: Maybe<Scalars['String']>;
};

export type BannerTranslationCreateInput = {
  action?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type BannerTranslationUpdateInput = {
  action?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BannerUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  imageBR?: InputMaybe<Scalars['String']>;
  imageTL?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<BannerTranslationUpdateInput>>;
  url?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Billing = {
  __typename?: 'Billing';
  address: Scalars['String'];
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  postalCode: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  user: User;
};

export type BillingCreateInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type BillingUpdateInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type Cart = {
  __typename?: 'Cart';
  count: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  items: Maybe<Array<CartItem>>;
  total: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
};

export type CartItem = {
  __typename?: 'CartItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  product: Product;
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type CartItemInput = {
  productId?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type Category = {
  __typename?: 'Category';
  active: Maybe<Scalars['Boolean']>;
  childs: Maybe<Array<Category>>;
  description: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  parent: Maybe<Category>;
  position: Maybe<Scalars['Float']>;
  products: Maybe<Array<Product>>;
  showInCatalog: Maybe<Scalars['Boolean']>;
  showInHome: Maybe<Scalars['Boolean']>;
  showInMenu: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  translations: Maybe<Array<CategoryTranslation>>;
  url: Scalars['String'];
};

export type CategoryCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  showInCatalog?: InputMaybe<Scalars['Boolean']>;
  showInHome?: InputMaybe<Scalars['Boolean']>;
  showInMenu?: InputMaybe<Scalars['Boolean']>;
  translation: Array<CategoryTranslationCreateInput>;
  url: Scalars['String'];
};

export type CategoryShuffleInput = {
  id: Scalars['Float'];
};

export type CategoryTranslation = {
  __typename?: 'CategoryTranslation';
  categoryId: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  language: Scalars['String'];
  title: Maybe<Scalars['String']>;
};

export type CategoryTranslationCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CategoryTranslationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<CategoryTranslationCreateInput>>;
};

export type CategoryUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Float']>;
  position?: InputMaybe<Scalars['Float']>;
  showInCatalog?: InputMaybe<Scalars['Boolean']>;
  showInHome?: InputMaybe<Scalars['Boolean']>;
  showInMenu?: InputMaybe<Scalars['Boolean']>;
  translation?: InputMaybe<Array<CategoryTranslationUpdateInput>>;
  url?: InputMaybe<Scalars['String']>;
};

export type Chat = {
  __typename?: 'Chat';
  active: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  messages: Maybe<Array<Message>>;
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
};

export type ChatInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CurrentLanguages = {
  __typename?: 'CurrentLanguages';
  code: Scalars['String'];
  name: Scalars['String'];
  nativeName: Scalars['String'];
};

export type DetailsCreateInput = {
  pid: Scalars['Float'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
};

/** User gender */
export enum Gender {
  /** Female user gender */
  Female = 'FEMALE',
  /** Male user gender */
  Male = 'MALE'
}

export type IsoLanguage = {
  __typename?: 'ISOLanguage';
  code: Scalars['String'];
  name: Scalars['String'];
  nativeName: Scalars['String'];
};

export type LeangugeData = {
  __typename?: 'LeangugeData';
  admin: CurrentLanguages;
  client: CurrentLanguages;
  languages: Array<CurrentLanguages>;
};

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  id: Scalars['Float'];
  notify: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramInput = {
  customerId?: InputMaybe<Scalars['String']>;
  notify?: InputMaybe<Scalars['Boolean']>;
};

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  message: Scalars['String'];
  sender: Scalars['String'];
  viewed: Maybe<Scalars['Boolean']>;
};

export type MessageInput = {
  message: Scalars['String'];
  sender?: InputMaybe<Role>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBilling: Billing;
  addNavigation: Navigation;
  addSetting: Setting;
  addShiping: Shiping;
  addToCart: Cart;
  addToSavedCart: SavedCart;
  addToSchedule: Schedule;
  addToWishList: WishList;
  addVisitor: Scalars['Boolean'];
  adminLogin: AuthPayload;
  changeLang: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  checkPayment: Scalars['String'];
  clearCart: Cart;
  clearPages: Scalars['Boolean'];
  clearProducts: Scalars['Boolean'];
  clearSavedCart: SavedCart;
  clearTags: Scalars['Boolean'];
  closeChat: Scalars['Boolean'];
  createBanner: Banner;
  createCart: Cart;
  createCategory: Category;
  createChat: Chat;
  createLoyaltyProgram: LoyaltyProgram;
  createOrder: Order;
  createPage: Page;
  createProduct: Product;
  createProductBundle: ProductBundle;
  createProfile: UserProfile;
  createReview: Review;
  createSavedCart: SavedCart;
  createTag: Tag;
  createTranslation: Translation;
  createUser: User;
  createVariationType: VariationType;
  deleteBanner: Scalars['Int'];
  deleteBanners: Scalars['Int'];
  deleteBilling: Scalars['Boolean'];
  deleteCategory: Category;
  deleteFromCart: Cart;
  deleteFromSavedCart: SavedCart;
  deleteFromSchedule: Schedule;
  deleteLoyaltyProgram: Scalars['Int'];
  deleteLoyaltyPrograms: Scalars['Int'];
  deleteManyProductBundles: Scalars['Boolean'];
  deleteManyProducts: Scalars['Boolean'];
  deleteOrders: Array<Scalars['Int']>;
  deletePage: Page;
  deleteProduct: Product;
  deleteProductBundle: ProductBundle;
  deleteProductTranslation: ProductTranslation;
  deleteReview: Array<Scalars['Int']>;
  deleteReviews: Array<Scalars['Int']>;
  deleteSchedule: Schedule;
  deleteSchedules: Array<Scalars['Int']>;
  deleteSetting: Setting;
  deleteShiping: Shiping;
  deleteTag: Tag;
  deleteUser: User;
  idram: Scalars['String'];
  initPages: Scalars['Int'];
  initSettings: Scalars['Int'];
  initTranslations: Scalars['Float'];
  login: AuthPayload;
  logout: Scalars['Boolean'];
  newMessage: Message;
  newSchedule: Schedule;
  registration: AuthPayload;
  removeFromWishList: WishList;
  sendSMS: Scalars['Boolean'];
  setOrderStatus: Order;
  setPaymentStatus: Order;
  shceduleItemUpdate: ScheduleItem;
  shuffleCategories: Array<Category>;
  shuffleProducts: Array<Product>;
  tagedProductVisible: ProductToTag;
  updateBanner: Banner;
  updateBilling: Billing;
  updateCategory: Category;
  updateLoyaltyProgram: LoyaltyProgram;
  updateNavigation: Navigation;
  updateOrder: Order;
  updatePage: Page;
  updateProduct: Product;
  updateProductBundle: ProductBundle;
  updateProductImages: Product;
  updateProfile: UserProfile;
  updateReview: Review;
  updateSchedule: Schedule;
  updateSetting: Setting;
  updateShiping: Shiping;
  updateTag: Tag;
  updateTranslation: Translation;
  updateUser: User;
  validateOrders: Scalars['Boolean'];
};


export type MutationAddBillingArgs = {
  input: BillingCreateInput;
  uid: Scalars['Int'];
};


export type MutationAddNavigationArgs = {
  input: NavigationCreateInput;
};


export type MutationAddSettingArgs = {
  input: SettingCreateInput;
};


export type MutationAddShipingArgs = {
  input: ShipingCreateInput;
};


export type MutationAddToCartArgs = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type MutationAddToSavedCartArgs = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  savedCartId: Scalars['Int'];
};


export type MutationAddToScheduleArgs = {
  input: ScheduleItemInput;
  scheduleId: Scalars['Int'];
};


export type MutationAddToWishListArgs = {
  productId: Scalars['Int'];
};


export type MutationAddVisitorArgs = {
  input: VisitorInput;
};


export type MutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangeLangArgs = {
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
  uid: Scalars['Int'];
};


export type MutationCheckPaymentArgs = {
  orderId: Scalars['Int'];
};


export type MutationCloseChatArgs = {
  id: Scalars['Int'];
};


export type MutationCreateBannerArgs = {
  input: BannerCreateInput;
};


export type MutationCreateCartArgs = {
  input?: InputMaybe<Array<CartItemInput>>;
};


export type MutationCreateCategoryArgs = {
  input: CategoryCreateInput;
  parentId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateChatArgs = {
  input: ChatInput;
};


export type MutationCreateLoyaltyProgramArgs = {
  input: LoyaltyProgramInput;
  uid: Scalars['Int'];
};


export type MutationCreateOrderArgs = {
  detailInput: Array<DetailsCreateInput>;
  input: OrderCreateInput;
  payment: PaymentCreateInput;
  techDetailsInput: TechDetailsInput;
};


export type MutationCreatePageArgs = {
  input: PageCreateInput;
};


export type MutationCreateProductArgs = {
  category: Array<Scalars['Int']>;
  input: ProductCreateInput;
  tags?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationCreateProductBundleArgs = {
  input: ProductBundleCreateInput;
};


export type MutationCreateProfileArgs = {
  address: Array<ShipingCreateInput>;
  input: UserProfileCreateInput;
  uid: Scalars['Int'];
};


export type MutationCreateReviewArgs = {
  input: ReviewInput;
  oid?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateSavedCartArgs = {
  input?: InputMaybe<Array<SavedCartItemInput>>;
};


export type MutationCreateTagArgs = {
  input: TagCreateInput;
};


export type MutationCreateTranslationArgs = {
  input: TranslationCreateInput;
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCreateVariationTypeArgs = {
  input: VariationTypeCreateInput;
};


export type MutationDeleteBannerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteBillingArgs = {
  id: Scalars['Int'];
  uid: Scalars['Int'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFromCartArgs = {
  productId: Scalars['Int'];
};


export type MutationDeleteFromSavedCartArgs = {
  productId: Scalars['Int'];
  savedCartId: Scalars['Int'];
};


export type MutationDeleteFromScheduleArgs = {
  productId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};


export type MutationDeleteLoyaltyProgramArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteManyProductBundlesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteManyProductsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteOrdersArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePageArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductBundleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductTranslationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReviewArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSchedulesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteSettingArgs = {
  name: Scalars['String'];
};


export type MutationDeleteShipingArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  uid: Scalars['Int'];
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationNewMessageArgs = {
  chatId: Scalars['Int'];
  input: MessageInput;
};


export type MutationNewScheduleArgs = {
  input: ScheduleInput;
  techDetailsInput: TechDetailsInput;
};


export type MutationRegistrationArgs = {
  input: UserCreateInput;
};


export type MutationRemoveFromWishListArgs = {
  productId: Scalars['Int'];
};


export type MutationSendSmsArgs = {
  phone: Scalars['String'];
};


export type MutationSetOrderStatusArgs = {
  id: Scalars['Int'];
  status: OrderStatus;
};


export type MutationSetPaymentStatusArgs = {
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  status: PaymentStatus;
};


export type MutationShceduleItemUpdateArgs = {
  id: Scalars['Int'];
  input: ScheduleItemInput;
};


export type MutationShuffleCategoriesArgs = {
  input: Array<CategoryShuffleInput>;
};


export type MutationShuffleProductsArgs = {
  input: Array<ProductShuffleInput>;
};


export type MutationTagedProductVisibleArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateBannerArgs = {
  id: Scalars['Int'];
  input: BannerUpdateInput;
};


export type MutationUpdateBillingArgs = {
  id: Scalars['Int'];
  input: BillingUpdateInput;
  uid: Scalars['Int'];
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['Int'];
  input: CategoryUpdateInput;
};


export type MutationUpdateLoyaltyProgramArgs = {
  id: Scalars['Int'];
  input: LoyaltyProgramInput;
};


export type MutationUpdateNavigationArgs = {
  id: Scalars['Int'];
  input: NavigationUpdateInput;
};


export type MutationUpdateOrderArgs = {
  detailInput: Array<DetailsCreateInput>;
  id: Scalars['Int'];
  input: OrderUpdateInput;
};


export type MutationUpdatePageArgs = {
  id: Scalars['Int'];
  input: PageUpdateInput;
};


export type MutationUpdateProductArgs = {
  category: Array<Scalars['Int']>;
  id: Scalars['Int'];
  input: ProductUpdateInput;
  tags: Array<Scalars['Int']>;
};


export type MutationUpdateProductBundleArgs = {
  id: Scalars['Int'];
  input: ProductBundleUpdateInput;
};


export type MutationUpdateProductImagesArgs = {
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
};


export type MutationUpdateProfileArgs = {
  input: UserProfileUpdateInput;
  uid: Scalars['Int'];
};


export type MutationUpdateReviewArgs = {
  id: Scalars['Int'];
  input: ReviewInput;
};


export type MutationUpdateScheduleArgs = {
  input: ScheduleInput;
  scheduleId: Scalars['Int'];
};


export type MutationUpdateSettingArgs = {
  input: SettingUpdateInput;
  name: Scalars['String'];
};


export type MutationUpdateShipingArgs = {
  id: Scalars['Int'];
  input: ShipingUpdateInput;
};


export type MutationUpdateTagArgs = {
  id: Scalars['Int'];
  input: TagUpdateInput;
};


export type MutationUpdateTranslationArgs = {
  input: TranslationCreateInput;
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
  uid: Scalars['Int'];
};

export type Navigation = {
  __typename?: 'Navigation';
  id: Maybe<Scalars['Float']>;
  index: Maybe<Scalars['Float']>;
  isActive: Scalars['Boolean'];
  isFooter: Maybe<Scalars['Boolean']>;
  isHighlighted: Scalars['Boolean'];
  isMain: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  translations: Maybe<Array<NavigationTranslation>>;
  url: Scalars['String'];
};

export type NavigationCreateInput = {
  index?: InputMaybe<Scalars['Float']>;
  isActive: Scalars['Boolean'];
  isFooter: Scalars['Boolean'];
  isHighlighted: Scalars['Boolean'];
  isMain: Scalars['Boolean'];
  translations: Array<NavigationTranslationCreateInput>;
  url: Scalars['String'];
};

export type NavigationTranslation = {
  __typename?: 'NavigationTranslation';
  id: Scalars['Float'];
  local: Scalars['String'];
  value: Scalars['String'];
};

export type NavigationTranslationCreateInput = {
  local: Scalars['String'];
  value: Scalars['String'];
};

export type NavigationTranslationUpdateInput = {
  local?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type NavigationUpdateInput = {
  index?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isFooter?: InputMaybe<Scalars['Boolean']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  translations?: InputMaybe<Array<NavigationTranslationUpdateInput>>;
  url?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  additionalInfo: Maybe<Scalars['String']>;
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer: Maybe<Scalars['String']>;
  deliverDate: Scalars['DateTime'];
  delivererPhone: Scalars['String'];
  details: Array<OrderDetails>;
  id: Scalars['Float'];
  ordererPhone: Scalars['String'];
  payment: Payment;
  status: Scalars['String'];
  sum: Scalars['Float'];
  takeaway: Maybe<Scalars['Boolean']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  user: Maybe<User>;
};

export type OrderCreateInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
  customer?: InputMaybe<Scalars['String']>;
  deliverDate: Scalars['DateTime'];
  delivererPhone: Scalars['String'];
  ordererPhone: Scalars['String'];
  origin?: InputMaybe<OrderOrigin>;
  status: OrderStatus;
  sum: Scalars['Float'];
  takeaway?: InputMaybe<Scalars['Boolean']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  oid: Scalars['Float'];
  pid: Scalars['Float'];
  price: Scalars['Float'];
  product: Maybe<Product>;
  quantity: Scalars['Float'];
};

/** Order Origin */
export enum OrderOrigin {
  /** Order created by customer */
  Customer = 'CUSTOMER',
  /** Order automaticly created by system, from schedules */
  Schedule = 'SCHEDULE'
}

/** Order Status */
export enum OrderStatus {
  /** Order has been canceled */
  Canceled = 'CANCELED',
  /** Order has been completed */
  Completed = 'COMPLETED',
  /** Order has been delivered */
  Delivered = 'DELIVERED',
  /** Order in draft status */
  Draft = 'DRAFT',
  /** Order paste and processing */
  Processing = 'PROCESSING',
  /** Order has been shipped */
  Shipped = 'SHIPPED'
}

export type OrderTechDetails = {
  __typename?: 'OrderTechDetails';
  browser: Scalars['String'];
  id: Scalars['Float'];
  ip: Scalars['String'];
  os: Scalars['String'];
  platform: Scalars['String'];
};

export type OrderUpdateInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  deliverDate?: InputMaybe<Scalars['DateTime']>;
  delivererPhone?: InputMaybe<Scalars['String']>;
  ordererPhone?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  sum?: InputMaybe<Scalars['Float']>;
  takeaway?: InputMaybe<Scalars['Boolean']>;
};

export type Page = {
  __typename?: 'Page';
  content: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  translations: Maybe<Array<PageTranslation>>;
  url: Maybe<Scalars['String']>;
};

export type PageCreateInput = {
  name: Scalars['String'];
  translations: Array<PageTranslationInput>;
  url: Scalars['String'];
};

export type PageTranslation = {
  __typename?: 'PageTranslation';
  local: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type PageTranslationInput = {
  local: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PageUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<PageTranslationInput>>;
  url?: InputMaybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Float'];
  status: Scalars['String'];
  type: Scalars['String'];
};

export type PaymentCreateInput = {
  status: PaymentStatus;
  type: Scalars['String'];
};

/** Payment status */
export enum PaymentStatus {
  /** Payment approved */
  Approved = 'APPROVED',
  /** Payment declined */
  Declined = 'DECLINED',
  /** Payment paid successfully */
  Paid = 'PAID',
  /** Payment pending */
  Pending = 'PENDING',
  /** Payment refounded */
  Refund = 'REFUND',
  /** Payment reversed */
  Reversed = 'REVERSED'
}

export type Product = {
  __typename?: 'Product';
  basePrice: Scalars['Float'];
  category: Array<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  id: Scalars['Float'];
  images: Maybe<Array<Scalars['String']>>;
  plot: Scalars['String'];
  position: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  quantity: Maybe<Scalars['Float']>;
  sku: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tag: Maybe<Array<Tag>>;
  tags: Maybe<Array<ProductToTag>>;
  title: Maybe<Scalars['String']>;
  translations: Maybe<Array<ProductTranslation>>;
  type: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  variations: Maybe<Array<Variation>>;
  weight: Maybe<Scalars['Float']>;
};

export type ProductBundle = {
  __typename?: 'ProductBundle';
  active: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  image: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  productToBundle: Maybe<Array<ProductToBundle>>;
  products: Maybe<Array<Product>>;
  title: Maybe<Scalars['String']>;
  translations: Maybe<Array<ProductTranslation>>;
};

export type ProductBundleCreateInput = {
  active: Scalars['Boolean'];
  image: Scalars['String'];
  pid: Array<Scalars['Int']>;
  price: Scalars['Float'];
  translations: Array<ProductTranslationCreateInput>;
};

export type ProductBundleUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['String']>;
  pid?: InputMaybe<Array<Scalars['Int']>>;
  price?: InputMaybe<Scalars['Float']>;
  translations?: InputMaybe<Array<ProductTranslationUpdateInput>>;
};

export type ProductCreateInput = {
  basePrice: Scalars['Float'];
  discount?: InputMaybe<Scalars['Float']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  plot: Scalars['String'];
  position?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  translations?: InputMaybe<Array<ProductTranslationCreateInput>>;
  url: Scalars['String'];
  variations?: InputMaybe<Array<VariantCreate>>;
};

export type ProductShuffleInput = {
  id: Scalars['Float'];
};

/** Product status */
export enum ProductStatus {
  /** Public for bundels. Showing only in bundles */
  Bundle = 'BUNDLE',
  /** Private products. dont show in store */
  Private = 'PRIVATE',
  /** Public products. Showing in store */
  Public = 'PUBLIC'
}

export type ProductToBundle = {
  __typename?: 'ProductToBundle';
  product: Maybe<Product>;
};

export type ProductToTag = {
  __typename?: 'ProductToTag';
  id: Maybe<Scalars['Float']>;
  product: Maybe<Product>;
  tag: Maybe<Tag>;
  visible: Maybe<Scalars['Boolean']>;
};

export type ProductTranslation = {
  __typename?: 'ProductTranslation';
  description: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  language: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
};

export type ProductTranslationCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ProductTranslationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ProductUpdateInput = {
  basePrice?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  plot?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  tag?: InputMaybe<Scalars['Int']>;
  translations?: InputMaybe<Array<ProductTranslationUpdateInput>>;
  url?: InputMaybe<Scalars['String']>;
  variations?: InputMaybe<Array<VariantUpdate>>;
};

export type Query = {
  __typename?: 'Query';
  activeChats: Array<Chat>;
  activeOrders: Array<Order>;
  allProductBundles: Array<ProductBundle>;
  allProducts: Array<Product>;
  allSchedules: Array<Schedule>;
  allSettings: Array<Setting>;
  arca: Scalars['String'];
  arcaStatus: Scalars['Boolean'];
  banner: Banner;
  banners: Array<Banner>;
  cart: Cart;
  chat: Chat;
  clear: Scalars['Boolean'];
  clearCategories: Scalars['Boolean'];
  clearProductBundles: Scalars['Boolean'];
  clearSettings: Scalars['Boolean'];
  clearTranslations: Scalars['Boolean'];
  clearWishList: Scalars['Boolean'];
  clientTranslations: Scalars['String'];
  confirmUser: Scalars['Boolean'];
  currentUser: Maybe<User>;
  currentUserBilling: Array<Billing>;
  currentUserShiping: Array<Shiping>;
  footerNavigation: Array<Navigation>;
  getBillingById: Billing;
  getBillings: Array<Billing>;
  getCategories: Array<Category>;
  getCategory: Category;
  getCategoryById: Category;
  getCategoryByUrl: Category;
  getProduct: Product;
  getProductById: Product;
  getProductByUrl: Product;
  getProductWithCategory: Array<Product>;
  getShipingById: Shiping;
  getShipings: Array<Shiping>;
  getUsers: Array<User>;
  getUsersByEmail: User;
  getUsersById: User;
  initNavigation: Scalars['Float'];
  install: Scalars['Boolean'];
  isoLanguages: Array<IsoLanguage>;
  languageData: LeangugeData;
  loyaltyProgram: LoyaltyProgram;
  loyaltyPrograms: Array<LoyaltyProgram>;
  mainNavigation: Array<Navigation>;
  navigation: Array<Navigation>;
  order: Order;
  orderHistory: Array<Order>;
  orders: Array<Order>;
  page: Page;
  pageByName: Page;
  pages: Array<Page>;
  passwordless: Scalars['Boolean'];
  productBundle: ProductBundle;
  productsByTag: Array<Product>;
  profile: UserProfile;
  resetSettings: Array<Setting>;
  review: Review;
  reviews: Array<Review>;
  salesReport: Array<SalesReport>;
  savedCart: SavedCart;
  savedCarts: Array<SavedCart>;
  schedule: Schedule;
  schedules: Array<Schedule>;
  setting: Setting;
  settingGroup: Array<Setting>;
  settingsTree: Scalars['String'];
  tag: Tag;
  tagedProducts: Array<ProductToTag>;
  tags: Array<Tag>;
  translation: Translation;
  translationById: Translation;
  translations: Array<Translation>;
  unreadChats: Array<UnreadChat>;
  uptime: Scalars['Float'];
  userActiveOrders: Array<Order>;
  userWhishList: Array<WishList>;
  visitorStats: VisitorStats;
  watchSchedules: Schedule;
};


export type QueryArcaArgs = {
  input: ArcaInput;
};


export type QueryArcaStatusArgs = {
  input: ArcaStatusInput;
};


export type QueryBannerArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryChatArgs = {
  id: Scalars['Int'];
};


export type QueryClientTranslationsArgs = {
  code: Scalars['String'];
};


export type QueryConfirmUserArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type QueryCurrentUserBillingArgs = {
  uid: Scalars['Int'];
};


export type QueryGetBillingByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCategoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryGetCategoryByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCategoryByUrlArgs = {
  url: Scalars['String'];
};


export type QueryGetProductArgs = {
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetProductByUrlArgs = {
  url: Scalars['String'];
};


export type QueryGetProductWithCategoryArgs = {
  category: Array<Scalars['Int']>;
};


export type QueryGetShipingByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetUsersByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUsersByIdArgs = {
  uid: Scalars['Int'];
};


export type QueryLoyaltyProgramArgs = {
  id: Scalars['Int'];
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrdersArgs = {
  statuses?: InputMaybe<Array<OrderStatus>>;
};


export type QueryPageArgs = {
  id: Scalars['Int'];
};


export type QueryPageByNameArgs = {
  name: Scalars['String'];
};


export type QueryPasswordlessArgs = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type QueryProductBundleArgs = {
  id: Scalars['Int'];
};


export type QueryProductsByTagArgs = {
  tagId: Scalars['Float'];
};


export type QueryProfileArgs = {
  uid: Scalars['Int'];
};


export type QueryReviewArgs = {
  id: Scalars['Int'];
};


export type QueryReviewsArgs = {
  oid?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};


export type QuerySavedCartArgs = {
  id: Scalars['Int'];
};


export type QueryScheduleArgs = {
  id: Scalars['Int'];
};


export type QuerySettingArgs = {
  name: Scalars['String'];
};


export type QuerySettingGroupArgs = {
  group: Scalars['String'];
};


export type QueryTagArgs = {
  id: Scalars['Int'];
};


export type QueryTranslationArgs = {
  name: Scalars['String'];
};


export type QueryTranslationByIdArgs = {
  id: Scalars['Int'];
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  message: Maybe<Scalars['String']>;
  oid: Maybe<Scalars['Float']>;
  order: Maybe<Product>;
  pid: Maybe<Scalars['Float']>;
  product: Maybe<Product>;
  rating: Maybe<Scalars['Float']>;
  status: Maybe<Scalars['String']>;
  uid: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ReviewInput = {
  message?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ReviewStatus>;
};

/** Review Status */
export enum ReviewStatus {
  /** Review verified and accepted */
  Accepted = 'ACCEPTED',
  /** Review has been BLOCKED */
  Blocked = 'BLOCKED',
  /** Review PENDING verification */
  Pending = 'PENDING'
}

/** The user roles. */
export enum Role {
  /** Administrator. */
  Admin = 'ADMIN',
  /** The default users. */
  Customer = 'CUSTOMER'
}

export type SalesReport = {
  __typename?: 'SalesReport';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type SavedCart = {
  __typename?: 'SavedCart';
  count: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  items: Maybe<Array<SavedCartItem>>;
  total: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
};

export type SavedCartItem = {
  __typename?: 'SavedCartItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  product: Product;
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SavedCartItemInput = {
  productId?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type Schedule = {
  __typename?: 'Schedule';
  address: Maybe<Shiping>;
  count: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  date: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  items: Maybe<Array<ScheduleItem>>;
  name: Maybe<Scalars['String']>;
  techDetails: Maybe<OrderTechDetails>;
  total: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
};

export type ScheduleInput = {
  addressId?: InputMaybe<Scalars['Float']>;
  count?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['DateTime']>;
  items?: InputMaybe<Array<ScheduleItemInput>>;
  name?: InputMaybe<Scalars['String']>;
  total?: InputMaybe<Scalars['Float']>;
};

export type ScheduleItem = {
  __typename?: 'ScheduleItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  product: Product;
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ScheduleItemInput = {
  productId?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type Setting = {
  __typename?: 'Setting';
  description: Maybe<Scalars['String']>;
  group: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SettingCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SettingUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Shiping = {
  __typename?: 'Shiping';
  address: Scalars['String'];
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isSchedule: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  productSchedule: Array<Schedule>;
  region: Maybe<Scalars['String']>;
  user: User;
};

export type ShipingCreateInput = {
  address: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type ShipingUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatClosed: Chat;
  chatMessages: Message;
  currentUserChanged: User;
  languageChanges: Scalars['String'];
  newChat: Chat;
  newOrder: Order;
  newReview: Review;
  newUnreadMessage: Array<UnreadChat>;
  orderClosed: Order;
  scheduleToOrder: Array<Schedule>;
  usersChanged: Array<User>;
};


export type SubscriptionChatMessagesArgs = {
  id: Scalars['Int'];
};


export type SubscriptionScheduleToOrderArgs = {
  uid: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  color: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  products: Maybe<Array<ProductToTag>>;
  title: Scalars['String'];
  translations: Maybe<Array<TagTranslation>>;
};

export type TagCreateInput = {
  color?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<TagTranslationCreateInput>>;
};

export type TagTranslation = {
  __typename?: 'TagTranslation';
  description: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  local: Scalars['String'];
  title: Scalars['String'];
};

export type TagTranslationCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  local: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type TagTranslationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  local: Scalars['String'];
  title: Scalars['String'];
};

export type TagUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<TagTranslationUpdateInput>>;
};

export type TechDetailsInput = {
  browser: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  ip: Scalars['String'];
  os: Scalars['String'];
  platform: Scalars['String'];
};

export type Translation = {
  __typename?: 'Translation';
  id: Scalars['Float'];
  items: Array<TranslationItem>;
  name: Scalars['String'];
};

export type TranslationCreateInput = {
  items: Array<TranslationItemCreateInput>;
  name: Scalars['String'];
};

export type TranslationItem = {
  __typename?: 'TranslationItem';
  id: Scalars['Float'];
  local: Scalars['String'];
  value: Scalars['String'];
};

export type TranslationItemCreateInput = {
  local: Scalars['String'];
  value: Scalars['String'];
};

export type UnreadChat = {
  __typename?: 'UnreadChat';
  active: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  messages: Maybe<Array<Message>>;
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  active: Maybe<Scalars['Boolean']>;
  billing: Maybe<Array<Billing>>;
  cart: Maybe<Cart>;
  chat: Maybe<Chat>;
  confirmToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  loyaltyProgram: Maybe<LoyaltyProgram>;
  password: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  profile: Maybe<UserProfile>;
  role: Maybe<Scalars['String']>;
  savedCart: Maybe<Array<SavedCart>>;
  secret: Maybe<Scalars['String']>;
  shiping: Maybe<Array<Shiping>>;
  totp: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  wishList: Maybe<Array<WishList>>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  active: Maybe<Scalars['Boolean']>;
  address: Maybe<Scalars['String']>;
  avatar: Maybe<Scalars['String']>;
  birthdate: Maybe<Scalars['DateTime']>;
  firstname: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  lastname: Maybe<Scalars['String']>;
};

export type UserProfileCreateInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UserProfileUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['DateTime']>;
  firstname?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  lastname?: InputMaybe<Scalars['String']>;
};

export type UserUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<UserProfileUpdateInput>;
  role?: InputMaybe<Role>;
  totp?: InputMaybe<Scalars['Boolean']>;
};

export type VariantCreate = {
  type: Scalars['Float'];
  value: VariationCreateInput;
};

export type VariantUpdate = {
  type?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<VariationUpdateInput>;
};

export type Variation = {
  __typename?: 'Variation';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  product: Product;
  title: Scalars['String'];
  type: Maybe<VariationType>;
  updatedAt: Scalars['DateTime'];
  value: Maybe<Scalars['String']>;
};

export type VariationCreateInput = {
  title: Scalars['String'];
  value: Scalars['String'];
};

export type VariationType = {
  __typename?: 'VariationType';
  createdAt: Scalars['DateTime'];
  icon: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  variations: Maybe<Array<Variation>>;
};

export type VariationTypeCreateInput = {
  icon: Scalars['String'];
  name: Scalars['String'];
};

export type VariationUpdateInput = {
  title?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Visitor = {
  __typename?: 'Visitor';
  arch: Maybe<Scalars['String']>;
  browser: Scalars['String'];
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  cpu: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  ip: Scalars['String'];
  location: Maybe<Scalars['String']>;
  memory: Maybe<Scalars['Float']>;
  os: Scalars['String'];
  osVersion: Maybe<Scalars['String']>;
  platform: Maybe<Scalars['String']>;
};

export type VisitorInput = {
  agentVersion?: InputMaybe<Scalars['String']>;
  arch?: InputMaybe<Scalars['String']>;
  browser: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cpu?: InputMaybe<Scalars['String']>;
  ip: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  memory?: InputMaybe<Scalars['Float']>;
  os: Scalars['String'];
  osVersion?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
};

export type VisitorStatItem = {
  __typename?: 'VisitorStatItem';
  items: Array<Visitor>;
  visitors: Scalars['Float'];
  visits: Scalars['Float'];
};

export type VisitorStats = {
  __typename?: 'VisitorStats';
  month: VisitorStatItem;
  today: VisitorStatItem;
  total: VisitorStatItem;
  week: VisitorStatItem;
  year: VisitorStatItem;
  yesterday: VisitorStatItem;
};

export type WishList = {
  __typename?: 'WishList';
  product: Product;
  productId: Maybe<Scalars['Float']>;
  products: Maybe<Product>;
};

export type SettingQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SettingQuery = { __typename?: 'Query', setting: { __typename?: 'Setting', id: number, name: string, value: string, group: string, description: string | null } };

export type SettingGroupQueryVariables = Exact<{
  group: Scalars['String'];
}>;


export type SettingGroupQuery = { __typename?: 'Query', settingGroup: Array<{ __typename?: 'Setting', id: number, name: string, value: string, group: string, description: string | null }> };

export type UpdateSettingMutationVariables = Exact<{
  name: Scalars['String'];
  input: SettingUpdateInput;
}>;


export type UpdateSettingMutation = { __typename?: 'Mutation', updateSetting: { __typename?: 'Setting', id: number, name: string, value: string, group: string, description: string | null } };

export type AllTranslationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTranslationsQuery = { __typename?: 'Query', translations: Array<{ __typename?: 'Translation', name: string, items: Array<{ __typename?: 'TranslationItem', local: string, value: string }> }> };

export type TranslationsQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type TranslationsQuery = { __typename?: 'Query', clientTranslations: string };

export type MainNavigationQueryVariables = Exact<{ [key: string]: never; }>;


export type MainNavigationQuery = { __typename?: 'Query', mainNavigation: Array<{ __typename?: 'Navigation', id: number | null, url: string, isHighlighted: boolean, title: string, index: number | null }> };

export type FooterNavigationQueryVariables = Exact<{ [key: string]: never; }>;


export type FooterNavigationQuery = { __typename?: 'Query', footerNavigation: Array<{ __typename?: 'Navigation', id: number | null, url: string, isHighlighted: boolean, title: string, index: number | null }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', getCategories: Array<{ __typename?: 'Category', id: number, title: string | null, description: string | null, icon: string | null, showInMenu: boolean | null, showInHome: boolean | null, showInCatalog: boolean | null, url: string, parent: { __typename?: 'Category', id: number } | null, products: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> | null }> };

export type CategoryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
}>;


export type CategoryQuery = { __typename?: 'Query', getCategory: { __typename?: 'Category', id: number, title: string | null, description: string | null, icon: string | null, showInMenu: boolean | null, showInHome: boolean | null, showInCatalog: boolean | null, url: string, parent: { __typename?: 'Category', id: number } | null, products: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> | null } };

export type LoginMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', id: number, city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string }> | null, cart: { __typename?: 'Cart', id: number } | null, chat: { __typename?: 'Chat', id: number } | null, loyaltyProgram: { __typename?: 'LoyaltyProgram', id: number, notify: boolean | null } | null } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type PasswordlessQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type PasswordlessQuery = { __typename?: 'Query', passwordless: boolean };

export type SendSmsMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type SendSmsMutation = { __typename?: 'Mutation', sendSMS: boolean };

export type ProfileQueryVariables = Exact<{
  uid: Scalars['Int'];
}>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'UserProfile', id: number, firstname: string | null, lastname: string | null, avatar: string | null, gender: string | null } };

export type CreateProfileMutationVariables = Exact<{
  uid: Scalars['Int'];
  input: UserProfileCreateInput;
  address: Array<ShipingCreateInput> | ShipingCreateInput;
}>;


export type CreateProfileMutation = { __typename?: 'Mutation', createProfile: { __typename?: 'UserProfile', id: number, firstname: string | null, lastname: string | null, avatar: string | null, gender: string | null } };

export type UpdateProfileMutationVariables = Exact<{
  uid: Scalars['Int'];
  input: UserProfileUpdateInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'UserProfile', id: number, firstname: string | null, lastname: string | null, avatar: string | null, gender: string | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', id: number, city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string }> | null, cart: { __typename?: 'Cart', id: number } | null, chat: { __typename?: 'Chat', id: number } | null, loyaltyProgram: { __typename?: 'LoyaltyProgram', id: number, notify: boolean | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  uid: Scalars['Int'];
  input: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', id: number, city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string }> | null, cart: { __typename?: 'Cart', id: number } | null, chat: { __typename?: 'Chat', id: number } | null, loyaltyProgram: { __typename?: 'LoyaltyProgram', id: number, notify: boolean | null } | null } };

export type CurrentUserShipingQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserShipingQuery = { __typename?: 'Query', currentUserShiping: Array<{ __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string, isSchedule: boolean | null }> };

export type AddShipingMutationVariables = Exact<{
  input: ShipingCreateInput;
}>;


export type AddShipingMutation = { __typename?: 'Mutation', addShiping: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string, isSchedule: boolean | null } };

export type UpdateShipingMutationVariables = Exact<{
  id: Scalars['Int'];
  input: ShipingUpdateInput;
}>;


export type UpdateShipingMutation = { __typename?: 'Mutation', updateShiping: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string, isSchedule: boolean | null } };

export type DeleteShipingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShipingMutation = { __typename?: 'Mutation', deleteShiping: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string, isSchedule: boolean | null } };

export type UserWishListQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWishListQuery = { __typename?: 'Query', userWhishList: Array<{ __typename?: 'WishList', product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> };

export type AddToWidhListMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type AddToWidhListMutation = { __typename?: 'Mutation', addToWishList: { __typename?: 'WishList', product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } } };

export type RemoveFromWishListMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type RemoveFromWishListMutation = { __typename?: 'Mutation', removeFromWishList: { __typename?: 'WishList', product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } } };

export type AllProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProductsQuery = { __typename?: 'Query', allProducts: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, url: string, category: Array<number>, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> };

export type ProductQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
}>;


export type ProductQuery = { __typename?: 'Query', getProduct: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, url: string, images: Array<string> | null, category: Array<number>, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } };

export type ProductsWitCategoryQueryVariables = Exact<{
  category: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProductsWitCategoryQuery = { __typename?: 'Query', getProductWithCategory: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, url: string, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> };

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'Cart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type CreateCartMutationVariables = Exact<{
  input: Array<CartItemInput> | CartItemInput;
}>;


export type CreateCartMutation = { __typename?: 'Mutation', createCart: { __typename?: 'Cart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type AddToCartMutationVariables = Exact<{
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
}>;


export type AddToCartMutation = { __typename?: 'Mutation', addToCart: { __typename?: 'Cart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type DeleteFromCartMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type DeleteFromCartMutation = { __typename?: 'Mutation', deleteFromCart: { __typename?: 'Cart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type ClearCartMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearCartMutation = { __typename?: 'Mutation', clearCart: { __typename?: 'Cart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type SavedCartsQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedCartsQuery = { __typename?: 'Query', savedCarts: Array<{ __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null }> };

export type SavedCartQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SavedCartQuery = { __typename?: 'Query', savedCart: { __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type CreateSavedCartMutationVariables = Exact<{
  input: Array<SavedCartItemInput> | SavedCartItemInput;
}>;


export type CreateSavedCartMutation = { __typename?: 'Mutation', createSavedCart: { __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type AddToSavedCartMutationVariables = Exact<{
  savedCartId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
}>;


export type AddToSavedCartMutation = { __typename?: 'Mutation', addToSavedCart: { __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type DeleteFromSavedCartMutationVariables = Exact<{
  savedCartId: Scalars['Int'];
  productId: Scalars['Int'];
}>;


export type DeleteFromSavedCartMutation = { __typename?: 'Mutation', deleteFromSavedCart: { __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type ClearSavedCartMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearSavedCartMutation = { __typename?: 'Mutation', clearSavedCart: { __typename?: 'SavedCart', id: number, total: number | null, count: number | null, items: Array<{ __typename?: 'SavedCartItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type AllSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSchedulesQuery = { __typename?: 'Query', allSchedules: Array<{ __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null }> };

export type ScheduleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ScheduleQuery = { __typename?: 'Query', schedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type UpdateScheduleMutationVariables = Exact<{
  scheduleId: Scalars['Int'];
  input: ScheduleInput;
}>;


export type UpdateScheduleMutation = { __typename?: 'Mutation', updateSchedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type NewScheduleMutationVariables = Exact<{
  input: ScheduleInput;
  techDetailsInput: TechDetailsInput;
}>;


export type NewScheduleMutation = { __typename?: 'Mutation', newSchedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type DeleteScheduleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteScheduleMutation = { __typename?: 'Mutation', deleteSchedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type AddToScheduleMutationVariables = Exact<{
  scheduleId: Scalars['Int'];
  input: ScheduleItemInput;
}>;


export type AddToScheduleMutation = { __typename?: 'Mutation', addToSchedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type DeleteFromScheduleMutationVariables = Exact<{
  scheduleId: Scalars['Int'];
  productId: Scalars['Int'];
}>;


export type DeleteFromScheduleMutation = { __typename?: 'Mutation', deleteFromSchedule: { __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, address: { __typename?: 'Shiping', id: number, name: string | null, city: string | null, postalCode: string | null, address: string } | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null } };

export type ShceduleItemUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: ScheduleItemInput;
}>;


export type ShceduleItemUpdateMutation = { __typename?: 'Mutation', shceduleItemUpdate: { __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } } };

export type ScheduleToOrderSubscriptionVariables = Exact<{
  uid: Scalars['Int'];
}>;


export type ScheduleToOrderSubscription = { __typename?: 'Subscription', scheduleToOrder: Array<{ __typename?: 'Schedule', id: number, name: string | null, date: any | null, total: number | null, count: number | null, items: Array<{ __typename?: 'ScheduleItem', id: number, quantity: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } }> | null }> };

export type ChatQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ChatQuery = { __typename?: 'Query', chat: { __typename?: 'Chat', id: number, type: string | null, name: string | null, email: string | null, createdAt: any, updatedAt: any, messages: Array<{ __typename?: 'Message', id: number, sender: string, message: string, createdAt: any }> | null } };

export type CreateChatMutationVariables = Exact<{
  input: ChatInput;
}>;


export type CreateChatMutation = { __typename?: 'Mutation', createChat: { __typename?: 'Chat', id: number, type: string | null, name: string | null, email: string | null, createdAt: any, updatedAt: any, messages: Array<{ __typename?: 'Message', id: number, sender: string, message: string, createdAt: any }> | null } };

export type NewMessageMutationVariables = Exact<{
  chatId: Scalars['Int'];
  input: MessageInput;
}>;


export type NewMessageMutation = { __typename?: 'Mutation', newMessage: { __typename?: 'Message', id: number, sender: string, message: string, createdAt: any } };

export type CloseChatMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CloseChatMutation = { __typename?: 'Mutation', closeChat: boolean };

export type ChatMessagesSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ChatMessagesSubscription = { __typename?: 'Subscription', chatMessages: { __typename?: 'Message', id: number, sender: string, message: string, createdAt: any } };

export type AllProductBundlesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProductBundlesQuery = { __typename?: 'Query', allProductBundles: Array<{ __typename?: 'ProductBundle', id: number, title: string | null, description: string | null, price: number, image: string | null, active: boolean | null, products: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, description: string | null, plot: string, price: number, discount: number, basePrice: number, quantity: number | null, status: string, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, translations: Array<{ __typename?: 'ProductTranslation', id: number, language: string | null, title: string | null, description: string | null, unit: string | null }> | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> | null, translations: Array<{ __typename?: 'ProductTranslation', id: number, language: string | null, title: string | null, description: string | null }> | null }> };

export type ProductBundleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductBundleQuery = { __typename?: 'Query', productBundle: { __typename?: 'ProductBundle', id: number, title: string | null, description: string | null, price: number, image: string | null, active: boolean | null, products: Array<{ __typename?: 'Product', id: number, sku: string | null, title: string | null, description: string | null, plot: string, price: number, discount: number, basePrice: number, quantity: number | null, status: string, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, translations: Array<{ __typename?: 'ProductTranslation', id: number, language: string | null, title: string | null, description: string | null, unit: string | null }> | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null }> | null, translations: Array<{ __typename?: 'ProductTranslation', id: number, language: string | null, title: string | null, description: string | null }> | null } };

export type CreateOrderMutationVariables = Exact<{
  input: OrderCreateInput;
  payment: PaymentCreateInput;
  detailInput: Array<DetailsCreateInput> | DetailsCreateInput;
  techDetailsInput: TechDetailsInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: number, address: string, ordererPhone: string, delivererPhone: string, deliverDate: any, status: string, sum: number, takeaway: boolean | null, additionalInfo: string | null, details: Array<{ __typename?: 'OrderDetails', pid: number }>, payment: { __typename?: 'Payment', id: number, type: string, status: string }, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', city: string | null, postalCode: string | null, address: string }> | null } | null } };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  input: OrderUpdateInput;
  detailInput: Array<DetailsCreateInput> | DetailsCreateInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'Order', id: number, address: string, ordererPhone: string, delivererPhone: string, deliverDate: any, status: string, sum: number, takeaway: boolean | null, additionalInfo: string | null, details: Array<{ __typename?: 'OrderDetails', quantity: number, price: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } | null }>, payment: { __typename?: 'Payment', id: number, type: string, status: string }, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', city: string | null, postalCode: string | null, address: string }> | null } | null } };

export type SetOrderStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: OrderStatus;
}>;


export type SetOrderStatusMutation = { __typename?: 'Mutation', setOrderStatus: { __typename?: 'Order', id: number, address: string, ordererPhone: string, delivererPhone: string, deliverDate: any, status: string, sum: number, takeaway: boolean | null, additionalInfo: string | null, details: Array<{ __typename?: 'OrderDetails', quantity: number, price: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } | null }>, payment: { __typename?: 'Payment', id: number, type: string, status: string }, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', city: string | null, postalCode: string | null, address: string }> | null } | null } };

export type UserActiveOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserActiveOrdersQuery = { __typename?: 'Query', userActiveOrders: Array<{ __typename?: 'Order', id: number, address: string, ordererPhone: string, delivererPhone: string, deliverDate: any, status: string, sum: number, takeaway: boolean | null, additionalInfo: string | null, details: Array<{ __typename?: 'OrderDetails', quantity: number, price: number, product: { __typename?: 'Product', id: number, sku: string | null, title: string | null, unit: string | null, description: string | null, quantity: number | null, weight: number | null, price: number, basePrice: number, discount: number, plot: string, status: string, type: string | null, images: Array<string> | null, createdAt: any | null, updatedAt: any | null, tag: Array<{ __typename?: 'Tag', id: number, name: string, title: string, color: string | null, icon: string | null }> | null } | null }>, payment: { __typename?: 'Payment', id: number, type: string, status: string }, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', city: string | null, postalCode: string | null, address: string }> | null } | null }> };

export type OrderHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderHistoryQuery = { __typename?: 'Query', orderHistory: Array<{ __typename?: 'Order', id: number, address: string, ordererPhone: string, delivererPhone: string, deliverDate: any, status: string, sum: number, takeaway: boolean | null, additionalInfo: string | null, createdAt: any, updatedAt: any | null, details: Array<{ __typename?: 'OrderDetails', pid: number, product: { __typename?: 'Product', plot: string, title: string | null, unit: string | null, price: number } | null }>, payment: { __typename?: 'Payment', id: number, type: string, status: string }, user: { __typename?: 'User', id: number, email: string | null, phone: string | null, role: string | null, profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null, gender: string | null, avatar: string | null, birthdate: any | null, active: boolean | null } | null, billing: Array<{ __typename?: 'Billing', city: string | null, postalCode: string | null, address: string }> | null, shiping: Array<{ __typename?: 'Shiping', city: string | null, postalCode: string | null, address: string }> | null } | null }> };

export type PageByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type PageByNameQuery = { __typename?: 'Query', pageByName: { __typename?: 'Page', id: number, name: string | null, url: string | null, title: string | null, content: string | null } };

export type ArcaQueryVariables = Exact<{
  input: ArcaInput;
}>;


export type ArcaQuery = { __typename?: 'Query', arca: string };

export type ArcaStatusQueryVariables = Exact<{
  input: ArcaStatusInput;
}>;


export type ArcaStatusQuery = { __typename?: 'Query', arcaStatus: boolean };

export type AddVisitorMutationVariables = Exact<{
  input: VisitorInput;
}>;


export type AddVisitorMutation = { __typename?: 'Mutation', addVisitor: boolean };

export type BannerQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type BannerQuery = { __typename?: 'Query', banner: { __typename?: 'Banner', id: number, url: string | null, value: string | null, title: string | null, label: string | null, action: string | null, imageTL: string | null, imageBR: string | null, active: boolean | null } };

export type ReviewsQueryVariables = Exact<{
  pid?: InputMaybe<Scalars['Int']>;
  oid?: InputMaybe<Scalars['Int']>;
}>;


export type ReviewsQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'Review', id: number, rating: number | null, message: string | null, createdAt: any, user: { __typename?: 'User', profile: { __typename?: 'UserProfile', firstname: string | null, lastname: string | null } | null } }> };

export type CreateReviewMutationVariables = Exact<{
  input: ReviewInput;
  pid?: InputMaybe<Scalars['Int']>;
  oid?: InputMaybe<Scalars['Int']>;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', createReview: { __typename?: 'Review', id: number, rating: number | null, message: string | null } };

export type CreateLoyaltyProgramMutationVariables = Exact<{
  uid: Scalars['Int'];
  input: LoyaltyProgramInput;
}>;


export type CreateLoyaltyProgramMutation = { __typename?: 'Mutation', createLoyaltyProgram: { __typename?: 'LoyaltyProgram', id: number, notify: boolean | null } };

export type UpdateLoyaltyProgramMutationVariables = Exact<{
  id: Scalars['Int'];
  input: LoyaltyProgramInput;
}>;


export type UpdateLoyaltyProgramMutation = { __typename?: 'Mutation', updateLoyaltyProgram: { __typename?: 'LoyaltyProgram', id: number, notify: boolean | null } };

export const SettingDocument = gql`
    query setting($name: String!) {
  setting(name: $name) {
    id
    name
    value
    group
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingGQL extends Apollo.Query<SettingQuery, SettingQueryVariables> {
    override document = SettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SettingGroupDocument = gql`
    query settingGroup($group: String!) {
  settingGroup(group: $group) {
    id
    name
    value
    group
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingGroupGQL extends Apollo.Query<SettingGroupQuery, SettingGroupQueryVariables> {
    override document = SettingGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSettingDocument = gql`
    mutation updateSetting($name: String!, $input: SettingUpdateInput!) {
  updateSetting(name: $name, input: $input) {
    id
    name
    value
    group
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSettingGQL extends Apollo.Mutation<UpdateSettingMutation, UpdateSettingMutationVariables> {
    override document = UpdateSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllTranslationsDocument = gql`
    query allTranslations {
  translations {
    name
    items {
      local
      value
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllTranslationsGQL extends Apollo.Query<AllTranslationsQuery, AllTranslationsQueryVariables> {
    override document = AllTranslationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TranslationsDocument = gql`
    query translations($code: String!) {
  clientTranslations(code: $code)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TranslationsGQL extends Apollo.Query<TranslationsQuery, TranslationsQueryVariables> {
    override document = TranslationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MainNavigationDocument = gql`
    query mainNavigation {
  mainNavigation {
    id
    url
    isHighlighted
    title
    index
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MainNavigationGQL extends Apollo.Query<MainNavigationQuery, MainNavigationQueryVariables> {
    override document = MainNavigationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FooterNavigationDocument = gql`
    query footerNavigation {
  footerNavigation {
    id
    url
    isHighlighted
    title
    index
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FooterNavigationGQL extends Apollo.Query<FooterNavigationQuery, FooterNavigationQueryVariables> {
    override document = FooterNavigationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoriesDocument = gql`
    query categories {
  getCategories {
    id
    title
    description
    icon
    showInMenu
    showInHome
    showInCatalog
    url
    parent {
      id
    }
    products {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoriesGQL extends Apollo.Query<CategoriesQuery, CategoriesQueryVariables> {
    override document = CategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoryDocument = gql`
    query category($id: Int, $url: String) {
  getCategory(id: $id, url: $url) {
    id
    title
    description
    icon
    showInMenu
    showInHome
    showInCatalog
    url
    parent {
      id
    }
    products {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryGQL extends Apollo.Query<CategoryQuery, CategoryQueryVariables> {
    override document = CategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($email: String, $phone: String, $password: String!) {
  login(email: $email, phone: $phone, password: $password) {
    token
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        id
        city
        postalCode
        address
      }
      shiping {
        id
        name
        city
        postalCode
        address
      }
      cart {
        id
      }
      chat {
        id
      }
      loyaltyProgram {
        id
        notify
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    override document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PasswordlessDocument = gql`
    query passwordless($email: String, $phone: String) {
  passwordless(email: $email, phone: $phone)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PasswordlessGQL extends Apollo.Query<PasswordlessQuery, PasswordlessQueryVariables> {
    override document = PasswordlessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendSmsDocument = gql`
    mutation sendSMS($phone: String!) {
  sendSMS(phone: $phone)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSmsGQL extends Apollo.Mutation<SendSmsMutation, SendSmsMutationVariables> {
    override document = SendSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileDocument = gql`
    query profile($uid: Int!) {
  profile(uid: $uid) {
    id
    firstname
    lastname
    avatar
    gender
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileGQL extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
    override document = ProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProfileDocument = gql`
    mutation createProfile($uid: Int!, $input: UserProfileCreateInput!, $address: [ShipingCreateInput!]!) {
  createProfile(uid: $uid, input: $input, address: $address) {
    id
    firstname
    lastname
    avatar
    gender
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProfileGQL extends Apollo.Mutation<CreateProfileMutation, CreateProfileMutationVariables> {
    override document = CreateProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProfileDocument = gql`
    mutation updateProfile($uid: Int!, $input: UserProfileUpdateInput!) {
  updateProfile(uid: $uid, input: $input) {
    id
    firstname
    lastname
    avatar
    gender
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProfileGQL extends Apollo.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> {
    override document = UpdateProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    email
    phone
    role
    profile {
      firstname
      lastname
      gender
      avatar
      birthdate
      active
    }
    billing {
      id
      city
      postalCode
      address
    }
    shiping {
      id
      name
      city
      postalCode
      address
    }
    cart {
      id
    }
    chat {
      id
    }
    loyaltyProgram {
      id
      notify
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    override document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($uid: Int!, $input: UserUpdateInput!) {
  updateUser(uid: $uid, input: $input) {
    id
    email
    phone
    role
    profile {
      firstname
      lastname
      gender
      avatar
      birthdate
      active
    }
    billing {
      id
      city
      postalCode
      address
    }
    shiping {
      id
      name
      city
      postalCode
      address
    }
    cart {
      id
    }
    chat {
      id
    }
    loyaltyProgram {
      id
      notify
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserShipingDocument = gql`
    query currentUserShiping {
  currentUserShiping {
    id
    name
    city
    postalCode
    address
    isSchedule
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserShipingGQL extends Apollo.Query<CurrentUserShipingQuery, CurrentUserShipingQueryVariables> {
    override document = CurrentUserShipingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddShipingDocument = gql`
    mutation addShiping($input: ShipingCreateInput!) {
  addShiping(input: $input) {
    id
    name
    city
    postalCode
    address
    isSchedule
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddShipingGQL extends Apollo.Mutation<AddShipingMutation, AddShipingMutationVariables> {
    override document = AddShipingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipingDocument = gql`
    mutation updateShiping($id: Int!, $input: ShipingUpdateInput!) {
  updateShiping(id: $id, input: $input) {
    id
    name
    city
    postalCode
    address
    isSchedule
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipingGQL extends Apollo.Mutation<UpdateShipingMutation, UpdateShipingMutationVariables> {
    override document = UpdateShipingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShipingDocument = gql`
    mutation deleteShiping($id: Int!) {
  deleteShiping(id: $id) {
    id
    name
    city
    postalCode
    address
    isSchedule
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShipingGQL extends Apollo.Mutation<DeleteShipingMutation, DeleteShipingMutationVariables> {
    override document = DeleteShipingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserWishListDocument = gql`
    query userWishList {
  userWhishList {
    product {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserWishListGQL extends Apollo.Query<UserWishListQuery, UserWishListQueryVariables> {
    override document = UserWishListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToWidhListDocument = gql`
    mutation addToWidhList($productId: Int!) {
  addToWishList(productId: $productId) {
    product {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToWidhListGQL extends Apollo.Mutation<AddToWidhListMutation, AddToWidhListMutationVariables> {
    override document = AddToWidhListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveFromWishListDocument = gql`
    mutation removeFromWishList($productId: Int!) {
  removeFromWishList(productId: $productId) {
    product {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveFromWishListGQL extends Apollo.Mutation<RemoveFromWishListMutation, RemoveFromWishListMutationVariables> {
    override document = RemoveFromWishListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllProductsDocument = gql`
    query allProducts {
  allProducts {
    id
    sku
    title
    unit
    description
    quantity
    weight
    price
    basePrice
    discount
    plot
    status
    type
    url
    category
    images
    tag {
      id
      name
      title
      color
      icon
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllProductsGQL extends Apollo.Query<AllProductsQuery, AllProductsQueryVariables> {
    override document = AllProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductDocument = gql`
    query product($id: Int, $url: String) {
  getProduct(id: $id, url: $url) {
    id
    sku
    title
    unit
    description
    quantity
    weight
    price
    basePrice
    discount
    plot
    status
    type
    url
    images
    tag {
      id
      name
      title
      color
      icon
    }
    category
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductGQL extends Apollo.Query<ProductQuery, ProductQueryVariables> {
    override document = ProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductsWitCategoryDocument = gql`
    query productsWitCategory($category: [Int!]!) {
  getProductWithCategory(category: $category) {
    id
    sku
    title
    unit
    description
    quantity
    weight
    price
    basePrice
    discount
    plot
    status
    type
    url
    images
    tag {
      id
      name
      title
      color
      icon
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductsWitCategoryGQL extends Apollo.Query<ProductsWitCategoryQuery, ProductsWitCategoryQueryVariables> {
    override document = ProductsWitCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartDocument = gql`
    query cart {
  cart {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartGQL extends Apollo.Query<CartQuery, CartQueryVariables> {
    override document = CartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCartDocument = gql`
    mutation createCart($input: [CartItemInput!]!) {
  createCart(input: $input) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCartGQL extends Apollo.Mutation<CreateCartMutation, CreateCartMutationVariables> {
    override document = CreateCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToCartDocument = gql`
    mutation addToCart($productId: Int!, $quantity: Int!) {
  addToCart(productId: $productId, quantity: $quantity) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToCartGQL extends Apollo.Mutation<AddToCartMutation, AddToCartMutationVariables> {
    override document = AddToCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFromCartDocument = gql`
    mutation deleteFromCart($productId: Int!) {
  deleteFromCart(productId: $productId) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFromCartGQL extends Apollo.Mutation<DeleteFromCartMutation, DeleteFromCartMutationVariables> {
    override document = DeleteFromCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClearCartDocument = gql`
    mutation clearCart {
  clearCart {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClearCartGQL extends Apollo.Mutation<ClearCartMutation, ClearCartMutationVariables> {
    override document = ClearCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavedCartsDocument = gql`
    query savedCarts {
  savedCarts {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavedCartsGQL extends Apollo.Query<SavedCartsQuery, SavedCartsQueryVariables> {
    override document = SavedCartsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavedCartDocument = gql`
    query savedCart($id: Int!) {
  savedCart(id: $id) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavedCartGQL extends Apollo.Query<SavedCartQuery, SavedCartQueryVariables> {
    override document = SavedCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSavedCartDocument = gql`
    mutation createSavedCart($input: [SavedCartItemInput!]!) {
  createSavedCart(input: $input) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSavedCartGQL extends Apollo.Mutation<CreateSavedCartMutation, CreateSavedCartMutationVariables> {
    override document = CreateSavedCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToSavedCartDocument = gql`
    mutation addToSavedCart($savedCartId: Int!, $productId: Int!, $quantity: Int!) {
  addToSavedCart(
    savedCartId: $savedCartId
    productId: $productId
    quantity: $quantity
  ) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToSavedCartGQL extends Apollo.Mutation<AddToSavedCartMutation, AddToSavedCartMutationVariables> {
    override document = AddToSavedCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFromSavedCartDocument = gql`
    mutation deleteFromSavedCart($savedCartId: Int!, $productId: Int!) {
  deleteFromSavedCart(savedCartId: $savedCartId, productId: $productId) {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFromSavedCartGQL extends Apollo.Mutation<DeleteFromSavedCartMutation, DeleteFromSavedCartMutationVariables> {
    override document = DeleteFromSavedCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClearSavedCartDocument = gql`
    mutation clearSavedCart {
  clearSavedCart {
    id
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClearSavedCartGQL extends Apollo.Mutation<ClearSavedCartMutation, ClearSavedCartMutationVariables> {
    override document = ClearSavedCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllSchedulesDocument = gql`
    query allSchedules {
  allSchedules {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllSchedulesGQL extends Apollo.Query<AllSchedulesQuery, AllSchedulesQueryVariables> {
    override document = AllSchedulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScheduleDocument = gql`
    query schedule($id: Int!) {
  schedule(id: $id) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduleGQL extends Apollo.Query<ScheduleQuery, ScheduleQueryVariables> {
    override document = ScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateScheduleDocument = gql`
    mutation updateSchedule($scheduleId: Int!, $input: ScheduleInput!) {
  updateSchedule(scheduleId: $scheduleId, input: $input) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateScheduleGQL extends Apollo.Mutation<UpdateScheduleMutation, UpdateScheduleMutationVariables> {
    override document = UpdateScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewScheduleDocument = gql`
    mutation newSchedule($input: ScheduleInput!, $techDetailsInput: TechDetailsInput!) {
  newSchedule(input: $input, techDetailsInput: $techDetailsInput) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewScheduleGQL extends Apollo.Mutation<NewScheduleMutation, NewScheduleMutationVariables> {
    override document = NewScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteScheduleDocument = gql`
    mutation deleteSchedule($id: Int!) {
  deleteSchedule(id: $id) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteScheduleGQL extends Apollo.Mutation<DeleteScheduleMutation, DeleteScheduleMutationVariables> {
    override document = DeleteScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToScheduleDocument = gql`
    mutation addToSchedule($scheduleId: Int!, $input: ScheduleItemInput!) {
  addToSchedule(scheduleId: $scheduleId, input: $input) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToScheduleGQL extends Apollo.Mutation<AddToScheduleMutation, AddToScheduleMutationVariables> {
    override document = AddToScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFromScheduleDocument = gql`
    mutation deleteFromSchedule($scheduleId: Int!, $productId: Int!) {
  deleteFromSchedule(scheduleId: $scheduleId, productId: $productId) {
    id
    name
    date
    total
    count
    address {
      id
      name
      city
      postalCode
      address
    }
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFromScheduleGQL extends Apollo.Mutation<DeleteFromScheduleMutation, DeleteFromScheduleMutationVariables> {
    override document = DeleteFromScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShceduleItemUpdateDocument = gql`
    mutation shceduleItemUpdate($id: Int!, $input: ScheduleItemInput!) {
  shceduleItemUpdate(id: $id, input: $input) {
    id
    quantity
    product {
      id
      sku
      title
      unit
      description
      quantity
      weight
      price
      basePrice
      discount
      plot
      status
      type
      images
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShceduleItemUpdateGQL extends Apollo.Mutation<ShceduleItemUpdateMutation, ShceduleItemUpdateMutationVariables> {
    override document = ShceduleItemUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScheduleToOrderDocument = gql`
    subscription scheduleToOrder($uid: Int!) {
  scheduleToOrder(uid: $uid) {
    id
    name
    date
    total
    count
    items {
      id
      quantity
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduleToOrderGQL extends Apollo.Subscription<ScheduleToOrderSubscription, ScheduleToOrderSubscriptionVariables> {
    override document = ScheduleToOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChatDocument = gql`
    query chat($id: Int!) {
  chat(id: $id) {
    id
    type
    name
    email
    createdAt
    updatedAt
    messages {
      id
      sender
      message
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChatGQL extends Apollo.Query<ChatQuery, ChatQueryVariables> {
    override document = ChatDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateChatDocument = gql`
    mutation createChat($input: ChatInput!) {
  createChat(input: $input) {
    id
    type
    name
    email
    createdAt
    updatedAt
    messages {
      id
      sender
      message
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChatGQL extends Apollo.Mutation<CreateChatMutation, CreateChatMutationVariables> {
    override document = CreateChatDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewMessageDocument = gql`
    mutation newMessage($chatId: Int!, $input: MessageInput!) {
  newMessage(chatId: $chatId, input: $input) {
    id
    sender
    message
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewMessageGQL extends Apollo.Mutation<NewMessageMutation, NewMessageMutationVariables> {
    override document = NewMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseChatDocument = gql`
    mutation closeChat($id: Int!) {
  closeChat(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseChatGQL extends Apollo.Mutation<CloseChatMutation, CloseChatMutationVariables> {
    override document = CloseChatDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChatMessagesDocument = gql`
    subscription chatMessages($id: Int!) {
  chatMessages(id: $id) {
    id
    sender
    message
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChatMessagesGQL extends Apollo.Subscription<ChatMessagesSubscription, ChatMessagesSubscriptionVariables> {
    override document = ChatMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllProductBundlesDocument = gql`
    query allProductBundles {
  allProductBundles {
    id
    title
    description
    price
    image
    active
    products {
      id
      sku
      title
      description
      plot
      price
      discount
      basePrice
      quantity
      status
      images
      translations {
        id
        language
        title
        description
        unit
      }
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
    translations {
      id
      language
      title
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllProductBundlesGQL extends Apollo.Query<AllProductBundlesQuery, AllProductBundlesQueryVariables> {
    override document = AllProductBundlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductBundleDocument = gql`
    query productBundle($id: Int!) {
  productBundle(id: $id) {
    id
    title
    description
    price
    image
    active
    products {
      id
      sku
      title
      description
      plot
      price
      discount
      basePrice
      quantity
      status
      images
      translations {
        id
        language
        title
        description
        unit
      }
      tag {
        id
        name
        title
        color
        icon
      }
      createdAt
      updatedAt
    }
    translations {
      id
      language
      title
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductBundleGQL extends Apollo.Query<ProductBundleQuery, ProductBundleQueryVariables> {
    override document = ProductBundleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderCreateInput!, $payment: PaymentCreateInput!, $detailInput: [DetailsCreateInput!]!, $techDetailsInput: TechDetailsInput!) {
  createOrder(
    input: $input
    payment: $payment
    detailInput: $detailInput
    techDetailsInput: $techDetailsInput
  ) {
    id
    address
    ordererPhone
    delivererPhone
    deliverDate
    status
    sum
    takeaway
    additionalInfo
    details {
      pid
    }
    payment {
      id
      type
      status
    }
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        city
        postalCode
        address
      }
      shiping {
        city
        postalCode
        address
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderGQL extends Apollo.Mutation<CreateOrderMutation, CreateOrderMutationVariables> {
    override document = CreateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: Int!, $input: OrderUpdateInput!, $detailInput: [DetailsCreateInput!]!) {
  updateOrder(id: $id, input: $input, detailInput: $detailInput) {
    id
    address
    ordererPhone
    delivererPhone
    deliverDate
    status
    sum
    takeaway
    additionalInfo
    details {
      quantity
      price
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
    payment {
      id
      type
      status
    }
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        city
        postalCode
        address
      }
      shiping {
        city
        postalCode
        address
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderGQL extends Apollo.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> {
    override document = UpdateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetOrderStatusDocument = gql`
    mutation setOrderStatus($id: Int!, $status: OrderStatus!) {
  setOrderStatus(id: $id, status: $status) {
    id
    address
    ordererPhone
    delivererPhone
    deliverDate
    status
    sum
    takeaway
    additionalInfo
    details {
      quantity
      price
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
    payment {
      id
      type
      status
    }
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        city
        postalCode
        address
      }
      shiping {
        city
        postalCode
        address
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetOrderStatusGQL extends Apollo.Mutation<SetOrderStatusMutation, SetOrderStatusMutationVariables> {
    override document = SetOrderStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserActiveOrdersDocument = gql`
    query userActiveOrders {
  userActiveOrders {
    id
    address
    ordererPhone
    delivererPhone
    deliverDate
    status
    sum
    takeaway
    additionalInfo
    details {
      quantity
      price
      product {
        id
        sku
        title
        unit
        description
        quantity
        weight
        price
        basePrice
        discount
        plot
        status
        type
        images
        tag {
          id
          name
          title
          color
          icon
        }
        createdAt
        updatedAt
      }
    }
    payment {
      id
      type
      status
    }
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        city
        postalCode
        address
      }
      shiping {
        city
        postalCode
        address
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserActiveOrdersGQL extends Apollo.Query<UserActiveOrdersQuery, UserActiveOrdersQueryVariables> {
    override document = UserActiveOrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderHistoryDocument = gql`
    query orderHistory {
  orderHistory {
    id
    address
    ordererPhone
    delivererPhone
    deliverDate
    status
    sum
    takeaway
    additionalInfo
    createdAt
    updatedAt
    details {
      pid
      product {
        plot
        title
        unit
        price
      }
    }
    payment {
      id
      type
      status
    }
    user {
      id
      email
      phone
      role
      profile {
        firstname
        lastname
        gender
        avatar
        birthdate
        active
      }
      billing {
        city
        postalCode
        address
      }
      shiping {
        city
        postalCode
        address
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderHistoryGQL extends Apollo.Query<OrderHistoryQuery, OrderHistoryQueryVariables> {
    override document = OrderHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PageByNameDocument = gql`
    query pageByName($name: String!) {
  pageByName(name: $name) {
    id
    name
    url
    title
    content
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PageByNameGQL extends Apollo.Query<PageByNameQuery, PageByNameQueryVariables> {
    override document = PageByNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArcaDocument = gql`
    query arca($input: ArcaInput!) {
  arca(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArcaGQL extends Apollo.Query<ArcaQuery, ArcaQueryVariables> {
    override document = ArcaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArcaStatusDocument = gql`
    query arcaStatus($input: ArcaStatusInput!) {
  arcaStatus(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArcaStatusGQL extends Apollo.Query<ArcaStatusQuery, ArcaStatusQueryVariables> {
    override document = ArcaStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddVisitorDocument = gql`
    mutation addVisitor($input: VisitorInput!) {
  addVisitor(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddVisitorGQL extends Apollo.Mutation<AddVisitorMutation, AddVisitorMutationVariables> {
    override document = AddVisitorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BannerDocument = gql`
    query banner($name: String!) {
  banner(name: $name) {
    id
    url
    value
    title
    label
    action
    imageTL
    imageBR
    active
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BannerGQL extends Apollo.Query<BannerQuery, BannerQueryVariables> {
    override document = BannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReviewsDocument = gql`
    query reviews($pid: Int, $oid: Int) {
  reviews(pid: $pid, oid: $oid) {
    id
    rating
    message
    createdAt
    user {
      profile {
        firstname
        lastname
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewsGQL extends Apollo.Query<ReviewsQuery, ReviewsQueryVariables> {
    override document = ReviewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReviewDocument = gql`
    mutation createReview($input: ReviewInput!, $pid: Int, $oid: Int) {
  createReview(input: $input, pid: $pid, oid: $oid) {
    id
    rating
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReviewGQL extends Apollo.Mutation<CreateReviewMutation, CreateReviewMutationVariables> {
    override document = CreateReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLoyaltyProgramDocument = gql`
    mutation createLoyaltyProgram($uid: Int!, $input: LoyaltyProgramInput!) {
  createLoyaltyProgram(uid: $uid, input: $input) {
    id
    notify
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLoyaltyProgramGQL extends Apollo.Mutation<CreateLoyaltyProgramMutation, CreateLoyaltyProgramMutationVariables> {
    override document = CreateLoyaltyProgramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLoyaltyProgramDocument = gql`
    mutation updateLoyaltyProgram($id: Int!, $input: LoyaltyProgramInput!) {
  updateLoyaltyProgram(id: $id, input: $input) {
    id
    notify
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLoyaltyProgramGQL extends Apollo.Mutation<UpdateLoyaltyProgramMutation, UpdateLoyaltyProgramMutationVariables> {
    override document = UpdateLoyaltyProgramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const namedOperations = {
  Query: {
    setting: 'setting',
    settingGroup: 'settingGroup',
    allTranslations: 'allTranslations',
    translations: 'translations',
    mainNavigation: 'mainNavigation',
    footerNavigation: 'footerNavigation',
    categories: 'categories',
    category: 'category',
    passwordless: 'passwordless',
    profile: 'profile',
    currentUser: 'currentUser',
    currentUserShiping: 'currentUserShiping',
    userWishList: 'userWishList',
    allProducts: 'allProducts',
    product: 'product',
    productsWitCategory: 'productsWitCategory',
    cart: 'cart',
    savedCarts: 'savedCarts',
    savedCart: 'savedCart',
    allSchedules: 'allSchedules',
    schedule: 'schedule',
    chat: 'chat',
    allProductBundles: 'allProductBundles',
    productBundle: 'productBundle',
    userActiveOrders: 'userActiveOrders',
    orderHistory: 'orderHistory',
    pageByName: 'pageByName',
    arca: 'arca',
    arcaStatus: 'arcaStatus',
    banner: 'banner',
    reviews: 'reviews'
  },
  Mutation: {
    updateSetting: 'updateSetting',
    login: 'login',
    logout: 'logout',
    sendSMS: 'sendSMS',
    createProfile: 'createProfile',
    updateProfile: 'updateProfile',
    updateUser: 'updateUser',
    addShiping: 'addShiping',
    updateShiping: 'updateShiping',
    deleteShiping: 'deleteShiping',
    addToWidhList: 'addToWidhList',
    removeFromWishList: 'removeFromWishList',
    createCart: 'createCart',
    addToCart: 'addToCart',
    deleteFromCart: 'deleteFromCart',
    clearCart: 'clearCart',
    createSavedCart: 'createSavedCart',
    addToSavedCart: 'addToSavedCart',
    deleteFromSavedCart: 'deleteFromSavedCart',
    clearSavedCart: 'clearSavedCart',
    updateSchedule: 'updateSchedule',
    newSchedule: 'newSchedule',
    deleteSchedule: 'deleteSchedule',
    addToSchedule: 'addToSchedule',
    deleteFromSchedule: 'deleteFromSchedule',
    shceduleItemUpdate: 'shceduleItemUpdate',
    createChat: 'createChat',
    newMessage: 'newMessage',
    closeChat: 'closeChat',
    createOrder: 'createOrder',
    updateOrder: 'updateOrder',
    setOrderStatus: 'setOrderStatus',
    addVisitor: 'addVisitor',
    createReview: 'createReview',
    createLoyaltyProgram: 'createLoyaltyProgram',
    updateLoyaltyProgram: 'updateLoyaltyProgram'
  },
  Subscription: {
    scheduleToOrder: 'scheduleToOrder',
    chatMessages: 'chatMessages'
  }
}